select{
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  margin-right: 1rem;
}

option{
  text-align: center;
}

.pokemon-selector{
  display: flex;
  align-items: center;
}