@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: fot-udkakugo-large-pr6n, sans-serif;
}

body {
  margin: 0;
  /* background: linear-gradient(
    100deg, 
    #ffe7e8 0%,
    #ffe7e8 5%,
    #c42834 6%,
    #c42834 10%,
    #e33241 11%,
    #e33241 90%,
    #c42834 91%,
    #c42834 95%,
    #dde9ff 96%,
    #dde9ff 100%
  ); */
  background:#ffe6e6;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}
.content {
  display: flex;
  justify-content: space-evenly;
  padding-top: 4rem;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
#root{
  width: 100%;
  height: 100%;
}
.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px){
  .content{
    flex-direction: row;
  }
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #e33241;
  border-bottom: 2px solid black;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 2vw;
  z-index: 5;
}


@media (min-width: 1024px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  
}

.nav-links a {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.26);
  color: black;
  text-decoration: none;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
  fill: black;
  
  background: linear-gradient(120deg,black, gray 70%, white 70%);
  background-size: 400% 400%;
  background-position:right center;


  transition: all 0.2s ease;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: white;
  background-position:left center;
  background: black;
  transition: all 0s;
  fill: white !important;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

@media (min-width: 1024px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    font-weight: bold;
    color: black;
    text-decoration: none;
  }

  .nav-links button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  font-weight: bold;
  background: linear-gradient(
    165deg, 
    #ffe7e8 0%,
    #ffe7e8 9%,
    #c42834 10%,
    #c42834 19%,
    #e33241 20%,
    #e33241 79%,
    #c42834 80%,
    #c42834 89%,
    #dde9ff 90%,
    #dde9ff 100%
  );
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.main-navigation__menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
}

.main-navigation__menu-btn span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
}

.main-navigation__title a {
    text-decoration: none;
    color: white;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.main-navigation__header-nav {
    display: none;
}

.main-navigation__drawer-nav {
    height: 100%;
}

@media (min-width: 1024px) {
    .main-navigation__menu-btn {
        display: none;
    }

    .main-navigation__header-nav {
        display: block;
    }
}
.pokemon_list{
    overflow-y: scroll;
}

.pokemon-item {
    display: flex;
    height: 2.5rem;
}
.hide_all{
    visibility: hidden;
}

.text-right {
    margin-right: 1rem;
}
.text-left{
    margin-left: 1rem;
}
.pokemon-item__anchor {
    text-decoration: none;
    color: black;
    padding: 0 .5rem;
    
    background-color: rgba(216, 132, 136, 0.37);
    border-radius: 2rem;
    box-shadow: 0 0 0 .1rem rgba(14, 25, 29, 0.205);
    display: inline-flex;
    align-items: center;
}

.pokemon-item-icon__container{
    float: left;
    /* background-color: white; */
    background: -webkit-linear-gradient(top, red, red 46%, black 46% , black 54%, white 54%, white);
    border-radius: 50%;
    padding: 0.3rem;
    box-shadow: 0 0 0 .1rem black;
}

.pokemon-item__info {
    text-align: center;
    width: 100%;
    font-weight: bold;
    padding: .7rem .2rem
}
.pokemon-item__icon__img{
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
/*  */
select{
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  margin-right: 1rem;
  align-self: center;
}

.selector{
  display: flex;
  padding: 5rem 0;
  height: 5rem;
  width: 25%;
  justify-content: center;
}
.type_icon{
  display: block;
  margin-left: .5rem;
  height: 2rem;
}

@media (max-width: 1023px){
  .type_icon{
    height: 1.5rem;
  }
}
.pokemon-details__main-art {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.pokemon-details__main-art__header__text {
  width: 100%;
}

.pokemon-details__main-art__header__text__info {
  display: flex;
  align-items: center;
  align-content: baseline;
  justify-content: space-between;
  font-size: .8rem;
  font-weight: bold;
}

.pokemon-details__main-art__header__text__name {
  font-weight: bold;
  font-size: 1.5rem;
}

.pokemon-details__main-art__header__text__info__types {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pokemon-details__img-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: .5rem;
  box-shadow: 0px 0px .5rem;
  border-radius: 1rem;
}



.pokemon-details__img {
  object-fit: contain;
  
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  
  
}
.pokemon-anchors{
  display: flex;
  justify-content: space-between;
  padding: .5rem .5rem;
}
.pokemon-anchors-mobile{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media(max-width: 1023px) {
  .pokemon-anchors{
    display: none;
  }
  .pokemon-details__img{
  min-height: 12rem;
  margin-top: 0.5rem;
  }
}

@media(min-width: 1024px) {
  .pokemon-anchors-mobile{
    display: none;
  }
  .pokemon-details__main-art {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 100%;
    min-width: 60%;
  }

  .pokemon-details__img-container {
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
  }

}
.Normal{
  background-color: #919aa2;
}
.Bug{
  background-color: #94c231;
}
.Electric{
  background-color: #f4d23c;
}

.Fighting{
  background-color: #ce416b;
}
.Ghost{
  background-color: #5269ad;
}
.Psychic{
  background-color: #fa7179;
}
.Flying{
  background-color: #8fa9de;
}
.Steel{
  background-color: #5a8ea2;
}
.Ice{
  background-color: #73cec0;
}
.Poison{
  background-color: #aa6bc8;
}
.Fire{
  background-color: #ff9d55;
}
.Dragon{
  background-color: #0b6dc3;
}
.Ground{
  background-color: #d97845;
}
.Water{
  background-color: #5090d6;
}
.Dark{
  background-color: #5a5465;
}
.Rock{
  background-color: #c5b78c;
}
.Grass{
  background-color: #63bc5a;
}
.Fairy{
  background-color: #ec8fe6;
}
.table-container{
  width: 100%;
}
table{
  margin-top: 1rem;
  border-collapse:collapse;
}

tr > th {
  background: #dbdbda;
  margin: 0;
  
  /* box-shadow: 0 0 5px rgba(0,0,0, .25); */
}

tr:not(:last-child) > th{
  border-bottom: 2px solid #d2d2d2;
}
tr > td{
  padding-left: .5rem;
  font-weight: 700;
  background: white; 
  width: 100%;
}
tr:not(:last-child) > td{
  border-bottom: 2px solid #f2f2f2;
}

@media (min-width: 768px){

  table{
    width: 100%;
  }
  tr{
    height: 2.5rem;
  }
  tr > th {
    width: 40%;
  }
}
.pokeart-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 2rem;
  padding: .5rem;
  box-shadow: 0px 0px 0.5rem black;
}
.pokeart-gallery-anchor{
  padding: .5rem;
  width: 50%;
  height: 100%;
  display: flex;
  
}
.pokeart-gallery-img {
  width: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 0.5rem black;
  border-radius: 1rem;
}

.gallery-name{
  font-weight: bold;
  width: 100%;
  margin-left: 2rem;
}
.gallery-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media(max-width: 1023px){
  .pokeart-gallery{
    margin: .5rem;
  }
  .pokeart-gallery-img{
    max-height: 6rem;
  }
}
@media (min-width: 1024px){
  .pokeart-gallery{
    width: 90%; 
  }
  .pokeart-gallery-img{
    max-height: 14rem;
  }
}
.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 1024) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
.button {
  font: inherit;
  padding: .5rem 1.5rem;
  border: 3px solid #2f2f2f;
  
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  border-radius: 8rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: black;
  color: white;
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.wrapper
{
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

}

.pokeball {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50% ;
  position: relative;
  overflow: hidden;
  border: 3px solid;
  -webkit-animation: frames .3s ease-out .1s infinite;
          animation: frames .3s ease-out .1s infinite;
}
.pokeball:after{
  content: '';
  position: absolute;
  width: 60px;
  height: 30px;
  background-color: red;
  border-bottom: 4px solid;
  top: -4px;
  left: -4px;
}

.pokeball:before{
  content: '';
  position: absolute;
  background-color: #fff;
  width: 10px;
  height:10px;
  border: 4px solid;
  border-radius: 50%;
  bottom: 18px;
  right: 18px;
  z-index: 1;
}

/* AnimationFrames */
@-webkit-keyframes frames{
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
@keyframes frames{
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.pokemon-main {
  display: flex;
  align-content: flex-start;
  padding-top: 1rem;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}

.pokemon-side {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  
}

@media (min-width: 1024px) {
  .pokemon-main {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .pokemon-side {
    width: 40%;
    padding: 2rem;
  }
}
.pokemon-main {
  display: flex;
  align-content: flex-start;
  padding-top: 1rem;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}

.pokemon-side {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  
}

@media (min-width: 1024px) {
  .pokemon-main {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .pokemon-side {
    width: 40%;
    padding: 2rem;
  }
}
.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
select{
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  margin-right: 1rem;
}

option{
  text-align: center;
}

.pokemon-selector{
  display: flex;
  align-items: center;
}
.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.image-upload__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  float: left;
  /* background-color: white; */
  background: -webkit-linear-gradient(top, red, red 48%, black 48%, black 52%, white 52%, white);
  border-radius: 50%;
  box-shadow: 0 0 0 .5rem black;
}



.error-text {
  color: white;
}

@media(max-width: 1023px) {
  .image-upload__preview {

    height: 75vw;
    width: 75vw;
    padding: 10vw;
  }

  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4rem;
  }
}

@media(min-width: 1024px) {
  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8rem;
  }

  .image-upload__preview {
    width: 55vh;
    height: 55vh;
    padding: 5vh;
  }
}
.pokeart-form{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}
.art-div{
  display: flex;
  flex-direction: column; 
}
.data-div{
  
  height: 30%;
}
.admin-main{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.review-art{
  display: flex;
  width: 100%;
}
.pending-art-main {
  width: 60%;
}
.side-panel{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 40%;
}
.action-panel{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 3px 4px rgba(0,0,0, 0.26);
  height: 10rem;
  border-radius: 10rem;
}
.action-title{
  margin-left:5rem;
  font-size: 2rem;
  font-weight: bold;
}
.action-container{
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}

@media (max-width: 1024px){
  .review-art{
    flex-direction: column;
  }
  .pending-art-main {
    width: 100%;
  }
  .side-panel{
    width: 100%;
  }.action-panel{             
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 3px 4px rgba(0,0,0, 0.26);
    height: 6rem;
    border-radius: 10rem;
  }
}
