.table-container{
  width: 100%;
}
table{
  margin-top: 1rem;
  border-collapse:collapse;
}

tr > th {
  background: #dbdbda;
  margin: 0;
  
  /* box-shadow: 0 0 5px rgba(0,0,0, .25); */
}

tr:not(:last-child) > th{
  border-bottom: 2px solid #d2d2d2;
}
tr > td{
  padding-left: .5rem;
  font-weight: 700;
  background: white; 
  width: 100%;
}
tr:not(:last-child) > td{
  border-bottom: 2px solid #f2f2f2;
}

@media (min-width: 768px){

  table{
    width: 100%;
  }
  tr{
    height: 2.5rem;
  }
  tr > th {
    width: 40%;
  }
}