.pokemon-main {
  display: flex;
  align-content: flex-start;
  padding-top: 1rem;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}

.pokemon-side {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  
}

@media (min-width: 1024px) {
  .pokemon-main {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .pokemon-side {
    width: 40%;
    padding: 2rem;
  }
}