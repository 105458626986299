.pokeart-form{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}
.art-div{
  display: flex;
  flex-direction: column; 
}
.data-div{
  
  height: 30%;
}