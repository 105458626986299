.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #e33241;
  border-bottom: 2px solid black;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 2vw;
  z-index: 5;
}


@media (min-width: 1024px) {
  .main-header {
    justify-content: space-between;
  }
}