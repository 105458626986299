.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  
}

.nav-links a {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.26);
  color: black;
  text-decoration: none;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
  fill: black;
  
  background: linear-gradient(120deg,black, gray 70%, white 70%);
  background-size: 400% 400%;
  background-position:right center;


  transition: all 0.2s ease;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: white;
  background-position:left center;
  background: black;
  transition: all 0s;
  fill: white !important;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

@media (min-width: 1024px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    font-weight: bold;
    color: black;
    text-decoration: none;
  }

  .nav-links button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}
