
.pokemon-item {
    display: flex;
    height: 2.5rem;
}
.hide_all{
    visibility: hidden;
}

.text-right {
    margin-right: 1rem;
}
.text-left{
    margin-left: 1rem;
}
.pokemon-item__anchor {
    text-decoration: none;
    color: black;
    padding: 0 .5rem;
    
    background-color: rgba(216, 132, 136, 0.37);
    border-radius: 2rem;
    box-shadow: 0 0 0 .1rem rgba(14, 25, 29, 0.205);
    display: inline-flex;
    align-items: center;
}

.pokemon-item-icon__container{
    float: left;
    /* background-color: white; */
    background: -webkit-linear-gradient(top, red, red 46%, black 46% , black 54%, white 54%, white);
    border-radius: 50%;
    padding: 0.3rem;
    box-shadow: 0 0 0 .1rem black;
}

.pokemon-item__info {
    text-align: center;
    width: 100%;
    font-weight: bold;
    padding: .7rem .2rem
}
.pokemon-item__icon__img{
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
/*  */