.pokemon-details__main-art {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.pokemon-details__main-art__header__text {
  width: 100%;
}

.pokemon-details__main-art__header__text__info {
  display: flex;
  align-items: center;
  align-content: baseline;
  justify-content: space-between;
  font-size: .8rem;
  font-weight: bold;
}

.pokemon-details__main-art__header__text__name {
  font-weight: bold;
  font-size: 1.5rem;
}

.pokemon-details__main-art__header__text__info__types {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pokemon-details__img-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: .5rem;
  box-shadow: 0px 0px .5rem;
  border-radius: 1rem;
}



.pokemon-details__img {
  object-fit: contain;
  
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  
  
}
.pokemon-anchors{
  display: flex;
  justify-content: space-between;
  padding: .5rem .5rem;
}
.pokemon-anchors-mobile{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media(max-width: 1023px) {
  .pokemon-anchors{
    display: none;
  }
  .pokemon-details__img{
  min-height: 12rem;
  margin-top: 0.5rem;
  }
}

@media(min-width: 1024px) {
  .pokemon-anchors-mobile{
    display: none;
  }
  .pokemon-details__main-art {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 100%;
    min-width: 60%;
  }

  .pokemon-details__img-container {
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
  }

}