.pokeart-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 2rem;
  padding: .5rem;
  box-shadow: 0px 0px 0.5rem black;
}
.pokeart-gallery-anchor{
  padding: .5rem;
  width: 50%;
  height: 100%;
  display: flex;
  
}
.pokeart-gallery-img {
  width: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 0.5rem black;
  border-radius: 1rem;
}

.gallery-name{
  font-weight: bold;
  width: 100%;
  margin-left: 2rem;
}
.gallery-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media(max-width: 1023px){
  .pokeart-gallery{
    margin: .5rem;
  }
  .pokeart-gallery-img{
    max-height: 6rem;
  }
}
@media (min-width: 1024px){
  .pokeart-gallery{
    width: 90%; 
  }
  .pokeart-gallery-img{
    max-height: 14rem;
  }
}