@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: fot-udkakugo-large-pr6n, sans-serif;
}

body {
  margin: 0;
  /* background: linear-gradient(
    100deg, 
    #ffe7e8 0%,
    #ffe7e8 5%,
    #c42834 6%,
    #c42834 10%,
    #e33241 11%,
    #e33241 90%,
    #c42834 91%,
    #c42834 95%,
    #dde9ff 96%,
    #dde9ff 100%
  ); */
  background:#ffe6e6;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}
.content {
  display: flex;
  justify-content: space-evenly;
  padding-top: 4rem;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
#root{
  width: 100%;
  height: 100%;
}
.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px){
  .content{
    flex-direction: row;
  }
}