.Normal{
  background-color: #919aa2;
}
.Bug{
  background-color: #94c231;
}
.Electric{
  background-color: #f4d23c;
}

.Fighting{
  background-color: #ce416b;
}
.Ghost{
  background-color: #5269ad;
}
.Psychic{
  background-color: #fa7179;
}
.Flying{
  background-color: #8fa9de;
}
.Steel{
  background-color: #5a8ea2;
}
.Ice{
  background-color: #73cec0;
}
.Poison{
  background-color: #aa6bc8;
}
.Fire{
  background-color: #ff9d55;
}
.Dragon{
  background-color: #0b6dc3;
}
.Ground{
  background-color: #d97845;
}
.Water{
  background-color: #5090d6;
}
.Dark{
  background-color: #5a5465;
}
.Rock{
  background-color: #c5b78c;
}
.Grass{
  background-color: #63bc5a;
}
.Fairy{
  background-color: #ec8fe6;
}