.type_icon{
  display: block;
  margin-left: .5rem;
  height: 2rem;
}

@media (max-width: 1023px){
  .type_icon{
    height: 1.5rem;
  }
}