.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  font-weight: bold;
  background: linear-gradient(
    165deg, 
    #ffe7e8 0%,
    #ffe7e8 9%,
    #c42834 10%,
    #c42834 19%,
    #e33241 20%,
    #e33241 79%,
    #c42834 80%,
    #c42834 89%,
    #dde9ff 90%,
    #dde9ff 100%
  );
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
