.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.image-upload__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  float: left;
  /* background-color: white; */
  background: -webkit-linear-gradient(top, red, red 48%, black 48%, black 52%, white 52%, white);
  border-radius: 50%;
  box-shadow: 0 0 0 .5rem black;
}



.error-text {
  color: white;
}

@media(max-width: 1023px) {
  .image-upload__preview {

    height: 75vw;
    width: 75vw;
    padding: 10vw;
  }

  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4rem;
  }
}

@media(min-width: 1024px) {
  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8rem;
  }

  .image-upload__preview {
    width: 55vh;
    height: 55vh;
    padding: 5vh;
  }
}